import { React, Fragment } from "react"

const Header = ({title, userName}) => {

    return (

        <Fragment>

            <div className="headerWrap">

                <div>
                    <span><h3>{title}</h3></span>
                </div>

                <div className="welcomeMsg">
                    <h4>Welcome back <span>{userName}</span></h4>
                </div>

            </div>

        </Fragment>

    )

}

export default Header

import style from  './css/style.module.css'
import avatar from './imge/avatar-icon.png'


const ToUpperCase = (text) =>{
    if(!text) return ''
    const letter = text.toString().replace(/\w/, c => c.toUpperCase())

    return letter
}

const CalculateAmt = data => {
    const total = data?.reduce((a, b) => a+b.amount, 0)

    return {total}
}



// const DOMAIN = `http://192.168.1.113:9090`
const DOMAIN = `https://locadmin.locpays.com`
const USER_DOMAIN = `https://locadmin.locpays.com`
const COLORS = {
    red: "#F00", 
    success: "#20C997",
    // success: "#008624",
    darkGreen: "#0E2346",
    // darkGreen: "#023020",
    deepGreen: '#023020',

    darkLime: "#F16722",
    // darkLime: "#d82028",

    lightLime: "#BBD6C5",
    lightBlue: "#CECECE",
    lime: "#0E2346",
    lightGreen: "#E7F9EF",
    lightGreen1: "#8EbCA0",
    primary: "#F16722",
    // primary: "#d82028",

    secondary: "#CDCDD2",
    white: "#fff",
    white2: '#F9F9F9',
    black: "#020202",
    blue: "#4096FE",
    gray: "#777777",
    gray1: "#EDEDEDED",
    gray2: '#F8F8F8',
    lightGray4: '#F8F8F9',
    lightGray: "#F5F6FB",
    lightGray2: '#757575',
    lightGray3: '#EFEFF1',


    transparentBlack1: 'rgba(2, 2, 2, 0.1)',
    transparentBlack3: 'rgba(2, 2, 2, 0.3)',
    transparentBlack5: 'rgba(2, 2, 2, 0.5)',
    transparentBlack7: 'rgba(2, 2, 2, 0.7)',
    transparentBlack9: 'rgba(2, 2, 2, 0.9)',

    transparentGray: 'rgba(44.7, 18.4, 21.6)',
    transparentDarkGray: 'rgba(44.7, 18.4, 21.6)',

    transparent: 'transparent',
};

const SIZES = {
    // global sizes
    base: 8,
    font: 14,
    radius: 12,
    padding: 24,
}

const PRODUCTID_IDS = 
   {

       // funding 
   '9000': 'Funding From',
   '1000': 'Providus Funding',
   '2000': 'Wema Funding',
   '1001': 'Wallet',

   '3000': 'Reg Commision From :',
   '4000': 'Upgrade commision From : ',
   '5000': 'Transfer to bank',

        '100': 'MTN Airtime',
        '200': 'GLO Airtime',
        '400': '9Mobile Airtime',
        '300': 'AIRTEL Airtime',
        '500': 'SMILE Airtime',
        // cable
        '600': 'DSTV',
        '700': 'GOTV',
         '800': 'STARTIMES',
        // data
         '101': 'MTN Direct Data',
         '107': 'MTN SME Data',
         '102': 'GLO Direct Data',
         '103': 'AIRTEL Direct Data',
         '104': '9Mobile Direct Data',
        '105': 'Smile Bundle',
        '106': 'Spectranet Data',

        // education 
        '900': 'WAEC',
        // Electricity
        '110': 'AEDC',
        '120': 'JED',
        '130': 'PHED',
        '140':  'KEDC',
        '150': 'KEDCO',
        '160': 'EKEDC',
        '170': 'IKEDC',
        '180': 'IBEDC'

}

   export {
    style,
    COLORS,
    SIZES,
    avatar,
    DOMAIN,
    USER_DOMAIN,
    ToUpperCase,
    PRODUCTID_IDS,
    CalculateAmt

   }
  
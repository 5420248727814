import axios from "axios"
import { setLevels } from "../reducers/levelReducer"

export class Level {
    constructor(dispactch) {
        this.dispactch = dispactch
    }
    dispactch = this.dispactch
    getLevels = async() =>{
        try {
            const {data, status} = await axios.get('/level')

            if(status == 200) return this.dispactch(setLevels(data))
            return []
        } catch (error) {
            return error
        }
    }

    createLevel = async(body) =>{
        try {
            const {data, status} = await axios.post('/level', body)

            if(status == 200) return {message: data.message}
            return []
        } catch (error) {
            return error
        }
    }

    setLevelLimit = async(body) =>{
        try {
            const {data, status} = await axios.put('/level', body)

            if(status == 200) return {message: data.message}
            return []
        } catch (error) {
            return error
        }
    }
}


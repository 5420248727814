import axios from 'axios'
import { setToken } from "../reducers/staffReducer"
import { setUsers } from "../reducers/userReducer"
import { setError, setIsLoading } from "../reducers/uiReducer"
import AsyncStorage from '@react-native-async-storage/async-storage'

export class User {
    constructor(dispatch) {
        this.dispatch = dispatch
    }

    getAll =  async (page, limit) =>{
        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            this.dispatch(setUsers(null))
            const { data, status } = await axios.get(`/users/page/${page}/${limit}`)
            if(status === 200) return this.dispatch(setUsers(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setUsers(null))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }


    searchUser =  async (query) =>{
        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            this.dispatch(setUsers(null))
            const { data, status } = await axios.get(`/users/search/${query}`)
            if(status === 200) return this.dispatch(setUsers(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setUsers(null))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }

    setUserLimit = async (body) => {
        try {
            this.dispatch(setIsLoading(true))
           
            const { data, status } = await axios.put(`/level`, body)
            if(status === 201 || status === 200) return this.dispatch(setIsLoading(false))

        } catch (error) {
            const {status} = error?.response

            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setUsers(null))

            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }
    
}
import { React, Fragment, useState, useEffect } from "react"
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from "react-redux";
import { Transaction } from "../app/customHooks/transactionHooks";
import { COLORS, style, ToUpperCase } from "../util";
import { selectUsers, setUsers } from "../app/reducers/userReducer";
import { User } from "../app/customHooks/userHooks";
import { selectToken } from "../app/reducers/staffReducer";

const QueryTable = () => {
    const dispatch = useDispatch()
    const tx = new Transaction(dispatch)
    const user = new User(dispatch)
    const [slide, setSlide] = useState()
    const users = useSelector(selectUsers)
    const [query, setQuery] = useState('')
    const [search, setSearch] = useState('')
    const token = useSelector(selectToken)

    const handleQuery = (e) => {
        e.preventDefault()
        tx.queryByTxIDTxType(query)
    }

    const handleSearch = (e) => {
        // e.preventDefault()
        if(!token) return
        if(!search?.length >=3) return
        user.searchUser(search)
    }

    useEffect(() => {
        handleSearch()
    }, [search])

    return (

        <Fragment>

            <div className={slide? "queryWrapSlide" : "queryWrap" }>
                
                {!slide&& 
                    
                    <div onClick={()=> setSlide(!slide)} className="searchIcon" title="Search">
                        <Icon icon="ic:baseline-search" />
                    </div>
                }


                {slide && (
                    <form className="searchIPT2"
                        onSubmit={e => handleQuery(e)}
                    >
                        <div className={`${style.inputContainer} ${style.shadow}`}>
                            <input 
                                type="text"
                                value={query}
                                onChange={e =>setQuery(e.target.value)}
                                placeholder="Search by ID, credit/ Debit" 
                            />

                          
                                <Icon icon="ic:sharp-send" 
                                    onClick={e =>handleQuery(e)}
                                />
                            
                        </div>

                    </form>
                
                )}
                {slide && (
                    <form className=""
                        onSubmit={e => handleQuery(e)}
                    >
                        <div className={`${style.inputContainer} ${style.shadow}`}>

                            <input 
                                type="text"
                                value={search}
                                className='userInput'
                                onChange={e =>setSearch(e.target.value)}
                                placeholder="Search by user" 
                            />

                            
                                <Icon 
                                    icon="ic:search" 
                                    className={style.icon} 
                                    style={{
                                        color: COLORS.gray
                                    }}
                                    onClick={e =>handleQuery(e)}
                                />
                           
                        </div>
                        {   users?.length > 0 &&
                            <section className={`${style.searchList} ${style.shadow}`}>
                                <ul>
                                    {
                                        users?.length > 0 &&
                                        users?.users?.map(item => {
                                            const {_id, firstName, lastName} = item
                                            return <ol
                                                key={_id}
                                                onClick={() => (
                                                    tx.queryByTxIDTxType(_id),
                                                    dispatch(setUsers(null)),
                                                    setSearch(`${ToUpperCase(firstName)} ${ToUpperCase(lastName)}`)
                                                )}
                                            >
                                                {`${ToUpperCase(firstName)} ${ToUpperCase(lastName)}`}
                                            </ol>
                                        })
                                    }
                                </ul>
                            </section>
                        }

                    </form>
                
                )}
               
                {
                    slide &&
                    <div onClick={()=> setSlide(!slide)} className="searchIconCancel" title="Close">
                        <Icon icon="ic:baseline-cancel" />
                    </div>
                }
              
                    
            </div>

        </Fragment>

    )

}

export default QueryTable
import React from 'react';
import { Icon } from '@iconify/react';
import { COLORS, PRODUCTID_IDS, SIZES, style, ToUpperCase } from '../util';
import UserCard from './UserCard';
import ListItem from './ListItem';
import { selectSelectedTx } from '../app/reducers/transactionReducer';
import { useSelector } from 'react-redux';

const TxDetails =() =>{
    const tx = useSelector(selectSelectedTx)
    return (
        <section className={style.txDetailsWrapper}>
            <div className={style.txDetailsHeader}>
                <div className={style.avatar}>
                    <Icon 
                        icon={tx?.tranStatus === 1 ? "bx:check" : tx?.tranStatus === 2 ? 'ic:sharp-pending' : 'pajamas:canceled-circle'}
                        className={style.largeIcon}
                        style={{
                            color: tx?.tranStatus === 1 ?'green' : tx?.tranStatus === 2 ? COLORS.darkLime: 'red'
                        }}
                    />
                </div>
                {   tx?.createData[0]?.message    &&
                    <h4
                        className={tx?.tranStatus === 1 ? style.success : tx?.tranStatus === 2 ? style.pending: style.error}
                    > {tx?.createData[0]?.message} </h4>
                }
            </div>
            <UserCard 
                amount={tx?.amount}
                tranType={tx?.tranType}
                bookBalance={tx?.bookId?.amount}
                oldBalance={tx?.oldBalance}
                avatar={tx?.memberId?.avatar}
            />
            <h3 className={style.title}>
                {`${ToUpperCase(tx?.memberId?.firstName)} ${ToUpperCase(tx?.memberId?.lastName)} `}
            </h3>

            <section>
                {
                    tx?.amount &&
                    <ListItem 
                        naira
                        title={PRODUCTID_IDS[`${tx?.productId}`]}
                        value={tx?.amount}
                    />
                }
                <ListItem 
                    title='Voucher'
                    value={`0.00`}
                />
                <ListItem
                    value={`0.00`}
                    title='Discount amount'
                />


                <div
                    style={{
                        paddingVertical: 15
                    }}
                >
                    <hr color={COLORS.white2} style={{marginTop: 40}} />
                    <div
                        style={
                            // styles.flexRow,
                            {   marginTop: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }
                        }
                    >
                        <p
                            style={{
                                marginTop: -20,
                                width: 200,
                                borderWidth: 1,
                                fontSize: 12,
                                fontWeight: '500',
                                padding: '10px 0px 10px 0px',
                                
                                textAlign: 'center',
                                color: COLORS.darkGreen,
                                borderRadius: SIZES.radius + 5,
                                borderColor: COLORS.gray1,
                                backgroundColor: COLORS.white2
                            }}
                        >
                            {new Date(tx?.createdAt).toUTCString()?.split(',')[1]?.split('GMT')[0]}
                        </p>
                    </div>
                </div>
                    

                {
                   tx?.createData[0]?.responseBody?.status &&
                    <ListItem 
                        naira
                        title='Status'
                        value={tx?.createData[0]?.responseBody?.status}
                    />
                }
                {
                    tx?.accNumber &&
                    <ListItem 
                        title='Account number'
                        value={tx?.accNumber }
                    /> 
                }
                {
                    tx?.accNumber &&
                    <ListItem 
                        title='Service provider'
                        value={PRODUCTID_IDS[`${tx?.productId}`]}
                    /> 
                }
                {
                    tx?.tranId &&
                    <ListItem 
                        title='Transaction number'
                        value={tx?.tranId}
                    /> 
                }
                {
                    tx?.createData[0]?.responseBody?.purchasedUnits &&
                    <ListItem 
                        title='Purchased units'
                        value={tx?.createData[0]?.responseBody?.purchasedUnits}
                    /> 
                }
                {
                    tx?.createData[0]?.responseBody?.token &&
                    <ListItem 
                        title='Token'
                        value='**** **** **** ****'
                    /> 
                }
                
                {
                    tx?.createData[0]?.responseBody?.customerName &&
                    <ListItem 
                        title='Customer name'
                        value={tx?.createData[0]?.responseBody?.customerName}
                    /> 
                }
                
                {
                    tx?.createData[0]?.responseBody?.customerAddress &&
                    <ListItem 
                        title='Customer address'
                        value={tx?.createData[0]?.responseBody?.customerAddress}
                    /> 
                }
                
                {
                    tx?.createData[0]?.responseBody?.requestType &&
                        <ListItem 
                        title='Vendor  type'
                        value={tx?.createData[0]?.responseBody?.requestType}
                    /> 
                }
            </section>
        </section>
    );
}

export default TxDetails;
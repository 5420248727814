import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    data: [],
    selected: null
}
const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTransactions: (state, action) => {
            state.data = action.payload
        },
        setSelectedTx: (state, action) => {
            state.selected = action.payload
        }
    }
})

export const { setSelectedTx, setTransactions } = transactionSlice.actions

export const selectSelectedTx = state => state.transaction.selected
export const selectTransactions = state => state.transaction.data

export default transactionSlice.reducer
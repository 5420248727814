import axios from 'axios'
import React from "react"
import Admin from "../pages/Admin"
import Login from "../pages/Login"
import {DOMAIN} from '../util'
import Overview from "../pages/Overview"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useSelector } from 'react-redux'
import { selectToken } from '../app/reducers/staffReducer'
import Visualize from '../pages/Visualize'

const Routing = () => {
    const token = useSelector(selectToken)
    axios.defaults.baseURL = `${DOMAIN}/api/v0.1`
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return (

        <Router>

            <Routes>
                <Route 
                    path="/"
                    element={<Login />}
                />

                <Route 
                    path="/dashboard"
                    element={<Overview />}
                />
                
                <Route 
                    path="/Admin"
                    element={<Admin />}
                />

                <Route 
                    path="/"
                    element={<Visualize />}
                />

            </Routes>

        </Router>

    )

}

export default Routing
import axios from "axios";
import { Icon } from '@iconify/react';
import { React, Fragment, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Staff } from "../app/customHooks/staffHook";
import { selectToken, setToken } from "../app/reducers/staffReducer";
import AsyncStorage from '@react-native-async-storage/async-storage'

const Aside = ({toggleSide, setToggleSide}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useSelector(selectToken)
    const handleAuth = async () => {
        const authToken = await AsyncStorage.getItem('token')
        if(!authToken) return navigate('/')

        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
        staff.handleRefreshToken()
        dispatch(setToken(authToken))
    }

    const handleLogOut = async () => {
        await AsyncStorage.removeItem('token')
        dispatch(setToken(null))
    }
    const staff = new Staff(dispatch)
    useEffect(() =>{
        handleAuth()
    }, [token])

    return (

        <Fragment>

            <div className={toggleSide? "asideAreaContentSlide" : "asideAreaContent"}>

                <div className={toggleSide? "logoSlide" : "logo"}>

                    <div className={toggleSide? "logoWrapSlide" : "logoWrap"} >
                        <img src={process.env.PUBLIC_URL + "../logo/locpay.png"} alt="logo" />
                    </div>

                    <div className={toggleSide? "toggleBtnSlide" : "toggleBtn" }>

                        {toggleSide? 
                            <Icon 
                                className="toggleBtnActive"
                                icon="bx:menu-alt-right" 
                                onClick={()=> setToggleSide(!toggleSide)}
                            /> : 
                            <Icon 
                                title="Open"
                                icon="bx:menu-alt-left" 
                                onClick={()=> setToggleSide(!toggleSide)}
                            />
                        }
                    </div>
                    
                </div>

                <div className="navOptionsWrap">

                    <Link to="/" className="navOption" title="Overview">

                        <div className={toggleSide? "navIconSlide" : "navIcon"}>
                            <Icon icon="pajamas:overview" />
                        </div>

                        <div className={toggleSide? "navNameSlide" : "navName"}>
                            Overview
                        </div>
                        
                    </Link>
                    
                    <Link to="/Admin" className="navOption" title="Admin">

                        <div className={toggleSide? "navIconSlide" : "navIcon"}>
                            <Icon icon="eos-icons:admin" />
                        </div>

                        <div className={toggleSide? "navNameSlide" : "navName"}>
                            Admin
                        </div>
                        
                    </Link>

                </div>

                <div 
                    className={
                        toggleSide? "logOutBtnSlide" : "logOutBtn"
                    }
                    onClick={handleLogOut}
                >Log Out</div>

                {toggleSide && 
                
                    <div 
                        onClick={handleLogOut}
                        className="logOutBtnIcon" title="Log Out"
                    >

                        <Icon icon="ri:logout-circle-r-fill" />

                    </div>

                }
                
            </div>

        </Fragment>

    )

}

export default Aside
import { React, Fragment } from "react"
import { Icon } from '@iconify/react';
import { COLORS, PRODUCTID_IDS, style } from "../util";
import { setPopover } from "../app/reducers/uiReducer";
import { useDispatch } from "react-redux";
import SpoolDate from "./SpoolDate";


const TransactionType = ({ query, status, setQuery, setSwap, setStatus, setIsDownload, handleSearch}) => {
    const dispatch = useDispatch()
    return (

        <Fragment>

            <div className="transWrap">
                
                <div className="transHead">

                    <section>
                        <h4>Transactions Report</h4>
                    </section>

                    

                    <div className="sortWrap">
                       
                        <SpoolDate 
                            query={query}
                            setSwap={() => setSwap(5)}
                            setQuery={setQuery}
                        />
                        <div>
                            <select 
                                name="product"
                                value={query.product}
                                onChange={e =>setQuery({
                                    ...query,
                                    product: e.target.value
                                })}
                                style={{
                                    color: COLORS.black,
                                    background: '#F7F7F7'
                                }}
                            >
                                <option value={0}>All Product</option>
                                {
                                    Object.keys(PRODUCTID_IDS).map(x =>{
                                        return <Fragment key={x}>
                                            <option value={x}> {PRODUCTID_IDS[x]} </option>
                                        </Fragment>
                                    })
                                }
                            </select>
                        </div>
                        <div
                        style={{marginRight:15}}
                        >
                            <select name="status" id=""
                                style={{
                                    width: 150,
                                    background: '#F7F7F7',
                                    color: 'black'
                                }}
                                value={status}
                                onChange={e =>{
                                    setSwap(6)
                                    setStatus(e.target.value)
                                }}
                            >
                                <option value={0}>All Status</option>
                                <option value={1}>Successful</option>
                                <option value={2}>Pending</option>
                                <option value={3}>Failed</option>

                            </select>
                        </div>
                        <div
                           className={style.exportBtnContainer}
                        >
                        <button 
                            className="queryBtn"
                            onClick={() =>(
                                setSwap(status > 0 ? 6 : 5),
                                handleSearch()
                            )}
                        >Sort</button>
                        <button 
                            
                            className="queryBtn"
                            onClick={() =>(
                                setSwap(6),
                                // handleSearch(),
                                setIsDownload(),
                                dispatch(setPopover(true)) 
                            )}
                        >Export</button>
                        </div>
                    </div>

                    

                </div>

                <div className="transCard">

                    <div className="card allTrans" onClick={()=> setSwap(1)}>
                        
                        <div className="cardRow1">
                            
                            <div className="cardIcon">
                                <Icon icon="carbon:collapse-all" />
                            </div>

                            <div className="cardName">All Transactions</div>

                        </div>

                        <div className="cardRow2">
                            <span>
                                <u>View details</u>    
                            </span> 
                            <span className="detailsIcon">
                                <Icon icon="bi:arrow-down-circle-fill" />
                            </span>
                        </div>

                    </div>

                    <div className="card completedTrans" onClick={()=> setSwap(2)} >
                        
                        <div className="cardRow1">
                            
                            <div className="cardIcon">
                                <Icon icon="fluent-mdl2:completed-solid" />
                            </div>

                            <div className="cardName">Successful Transactions</div>

                        </div>

                        <div className="cardRow2">
                            <span>
                                <u>View details</u>    
                            </span> 
                            <span className="detailsIcon">
                                <Icon icon="bi:arrow-down-circle-fill" />
                            </span>
                        </div>

                    </div>

                    <div className="card pendingTrans" onClick={()=> setSwap(3)} >
                        
                        <div className="cardRow1">
                            
                            <div className="cardIcon">
                                <Icon icon="ic:sharp-pending" />
                            </div>

                            <div className="cardName">Pending Transactions</div>

                        </div>

                        <div className="cardRow2">
                            <span>
                                <u>View details</u>    
                            </span> 
                            <span className="detailsIcon">
                                <Icon icon="bi:arrow-down-circle-fill" />
                            </span>
                        </div>

                    </div>

                    <div className="card canceledTrans" onClick={()=> setSwap(4)} >
                        
                        <div className="cardRow1">
                            
                            <div className="cardIcon">
                                <Icon icon="pajamas:canceled-circle" />
                            </div>

                            <div className="cardName">Canceled Transactions</div>

                        </div>

                        <div className="cardRow2">
                            <span>
                                <u>View details</u>    
                            </span> 
                            <span className="detailsIcon">
                                <Icon icon="bi:arrow-down-circle-fill" />
                            </span>
                        </div>

                    </div>

                </div>

            </div>

        </Fragment>

    )

}

export default TransactionType
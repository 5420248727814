import React, { Fragment } from 'react';
import { style } from '../util';

const Apploader = (props) =>{
    return (
        <Fragment>
            <section
                className={style.popoverContainer}
            >

                <div className={style.ldsroller}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
             </section>
        </Fragment>
    );
}

export default Apploader;
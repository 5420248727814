import { Fragment } from 'react'


const Chart = () => {
    return (
       <Fragment>
           <h3>hello from chart</h3>
       </Fragment>
    )
}

export default Chart
import { configureStore } from "@reduxjs/toolkit";

import uiReducer from './reducers/uiReducer'
import userReducer from './reducers/userReducer'
import staffReducer from './reducers/staffReducer'
import levelReducer from './reducers/levelReducer'
import transactionReducer from './reducers/transactionReducer'
export const store = configureStore({
    reducer: {
        ui: uiReducer,
        user: userReducer,
        staff: staffReducer,
        level: levelReducer,
        transaction: transactionReducer
    }
})
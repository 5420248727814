import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: [],
    selected: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUsers: (state, action) => {
            state.data = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selected = action.payload
        }
    }
})

export const { setUsers, setSelectedUser } = userSlice.actions
export const selectUsers = state => state.user.data
export const selectSelectedUser = state => state.user.selected

export default userSlice.reducer
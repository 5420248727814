import { React, Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { User } from "../app/customHooks/userHooks"
import { selectStaff, selectToken } from "../app/reducers/staffReducer"
import { selectIsLoading } from "../app/reducers/uiReducer"
import Apploader from "../components/Apploader"
import Aside from "../components/Aside"
import Chart from "../components/Chart"
import Header from "../components/Header"
import UserTable from "../components/UserTable"
import { ToUpperCase } from "../util"

const Visualize = () => {
    const dispatch = useDispatch()
    const user = new User(dispatch)
    const token = useSelector(selectToken)
    const session = useSelector(selectStaff)
    const isLoading = useSelector(selectIsLoading)

    const [query, setQuery] = useState({
        page: 1,
        limit: 0
    })
    const [toggleSide, setToggleSide] = useState(1)
    const handleSearch = () => {
        const {page, limit} = query
        
    }

    useEffect(() => {
        handleSearch()
    }, [])
    return (

        <Fragment>
            {
                isLoading &&
                <Apploader />
            }
            <div className="appWrapper">

                <div className={toggleSide? "asideAreaSlide" : "asideArea"}>

                    <Aside 
                        toggleSide={toggleSide}
                        setToggleSide={setToggleSide}
                    />

                </div>

                <div className={toggleSide? "sectionAreaSlide" : "sectionArea"} >
                    
                    <Header 
                        title={"Data Visualization"} 
                        userName={`${ToUpperCase(session?.firstName)} ${ToUpperCase(session?.lastName)}`}
                    />
                    

                    <div className="adminWrap">
                        
                        <Chart />

                    </div>

                </div>

            </div>


       </Fragment>
        
    )

}

export default Visualize
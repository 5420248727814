import { React, Fragment } from "react"
import { Icon } from '@iconify/react';
import style from '../css/style.module.css'
import { CalculateAmt, COLORS, PRODUCTID_IDS, SIZES, ToUpperCase } from "../util"
import { useDispatch, useSelector } from "react-redux"
import { selectTransactions, setSelectedTx } from "../app/reducers/transactionReducer"
import { selectIsLoading, setPopover } from "../app/reducers/uiReducer";

const RenderTr = ({ index, onClick, tranId, amount, memberId, productId, tranType, tranStatus, createdAt}) => {
    return (
        <Fragment>
            <tr 
                onClick={() =>onClick()}
                className={index % 2 ? 'trDecorationColor': ''}
            >
                <td> {index+1} </td>
                <td>{PRODUCTID_IDS[`${productId}`]}</td>
                <td className={tranType ==='cr' ? style.success : style.error}> 
                   
                        { tranType === 'dr' ? '-' : '+'}
                   
                    {amount?.toLocaleString()}
                </td>
                <td>{new Date(createdAt).toLocaleDateString()}</td>
                <td>{tranId}</td>
                {/* <td>{tranId}</td> */}
                <td> {`${ToUpperCase(memberId?.firstName)} ${ToUpperCase(memberId?.lastName)}`} </td>
                
                <td>
                    <div className={ tranStatus === 1 ? "successful" : tranStatus === 2 ? 'pending' : 'failed' }
                    > {tranStatus == 1 ? 'Successful' :  tranStatus == 2 ? 'Pending' : 'Failed'}
                    </div>
                  
                </td>
                <td
                    // onClick={() => (
                    //     dispatch(setSelectedTx(selected)),
                    //     dispatch(setPopover(true))
                    // )}
                >
                    <Icon icon="ic:more" className='icon' />
                </td>
            </tr>
        </Fragment>
    )
}

const TransactionTable = ({query, setQuery, handleSearch, setIsDownload}) => {
    const dispatch = useDispatch()
    const isLoading = useSelector(selectIsLoading)
    const transactions = useSelector(selectTransactions)

    return (
        
        <Fragment>

            <div className="leftsub">
                {
                    transactions?.length > 0 &&
                
                <table className='tableHead'>
                    <thead>
                      
                        <th>S/N</th>
                        <th>Description</th>
                        <th>Amount (&#x20A6;)</th>
                        <th>Date</th>
                        <th>Transaction ID</th>
                        <th>User</th>
                        <th>Status</th>
                        <th>View</th>
                       
                    </thead>
              
                
              
                        <tbody className='tableBody'>
                            {
                                transactions?.length > 0 &&
                                transactions?.transactions?.map((item, index) => {
                                    return <RenderTr 
                                        key={index+1*5676545}
                                        index={index}
                                        {...item}
                                       
                                        onClick={() => (
                                            dispatch(setSelectedTx(item)),
                                            dispatch(setPopover(true))
                                        )}
                                       
                                    />
                                })
                            }
                            <tr>
                                <td colSpan={2}>
                                    <h3>Total Amount</h3>
                                </td>
                                <td>
                                    <h3>
                                        &#x20A6; {CalculateAmt(transactions?.transactions).total?.toLocaleString()}
                                    </h3>
                                </td>
                                <td colSpan={3}></td>
                                <td colSpan={2}
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    <button
                                        onClick={() =>(
                                            setIsDownload(true),
                                            dispatch(setPopover(true))
                                        )}
                                        className={style.downloadBtn}
                                    >Export To Excel</button>
                                </td>
                                {/* <td></td> */}
                            </tr>
                        </tbody>
                  
                    </table>
              

                }
                
                <div style={{paddingBottom: SIZES.padding *3}} />

                {
                    transactions?.length >0 &&
                    <div className="paginationWrap">
                        <div
                            onClick={(e) => {
                                if(query?.page === 1) return e.preventDefault()
                                setQuery({
                                    ...query,
                                    page: query?.page-1
                                })
                                handleSearch()
                            }}
                           
                        >Previous</div>
                        {
                            transactions?.length ?
                            [...new Array(transactions?.pages)].slice(0,10).map((_, index) => {
                                const page = index+1
                                return  <div
                                    key={index+1}
                                    onClick={() => {
                                        setQuery({
                                            ...query,
                                            page: page
                                        })
                                        handleSearch()
                                    }}
                                    style={{
                                        color: query?.page === page ? COLORS.white : '',
                                        background: query?.page === page ? COLORS.darkGreen : ''
                                    }}
                                >
                                    <p>{index+1}</p>
                                </div>
                            })
                            :
                            null
                        }
                        <div
                            style={{
                                gap: 20,
                                paddingLeft: 5,
                                display: 'flex'
                            }}
                        >
                            <p style={{
                                color: COLORS.red
                            }}>Limit</p>
                            <form action=""
                                onSubmit={e =>{
                                    e.preventDefault()
                                    handleSearch()
                                }}
                            >
                                <input 
                                    type="number" 
                                    name="limit" 
                                    maxLength={transactions?.pages}
                                    value={query?.limit}
                                    placeholder='limit'
                                    onChange={e =>setQuery({
                                        ...query,
                                        limit: e.target.value
                                    })}
                                />
                            </form>
                        </div>
                        <div
                            style={{
                                color: COLORS.success
                            }}
                        >
                            <p> Page {`${query.page} 0f ${transactions?.pages === 0? 1 : transactions?.pages}`} </p>
                        </div>
                        <div
                            onClick={(e) => {
                                if(query?.page === transactions?.pages || transactions?.pages == 0) return e.preventDefault()
                                setQuery({
                                    ...query,
                                    page: query?.page+1
                                })
                                handleSearch()
                            }}
                        >
                            <p>Next</p>
                        </div>
                    </div>
                }


                {
                    !isLoading && transactions?.length === 0 &&

                    <div className={style.noRecord}>
                       <dt>
                            <Icon 
                                icon='ic:info'
                                style={{
                                    fontSize: 34,
                                    color: COLORS.red
                                }}

                            />
                            <h4
                                style={{
                                    color: COLORS.darkGreen
                                }}
                            >No transaction available on the selected criterias !!!</h4>
                       </dt>
                    </div>
                }

            </div>

        </Fragment>

    )

}

export default TransactionTable
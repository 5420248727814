import axios from "axios";
import { setStaff, setToken } from "../reducers/staffReducer";
import { setError, setIsLoading } from "../reducers/uiReducer";
import AsyncStorage from '@react-native-async-storage/async-storage'

export class Staff{
    constructor(dispatch){
        this.dispatch = dispatch
    }
    dispatch = this.dispatch
    staffLogIn = async (body) => {
        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            const { data, status } = await axios.post('/staff/login', body)
    
            if(status === 200) return this.dispatch(setToken(data.token)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
        }
    }

    handleRefreshToken = async () => {
        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            const { data, status } = await axios.get('/staff/session')
    
            if(status === 200) return this.dispatch(setStaff(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }

}

import axios from 'axios'
import React, { useEffect, useState } from 'react';
import logo from '../imge/locpayApp.png'
import { Staff } from '../app/customHooks/staffHook';
import { useDispatch, useSelector } from 'react-redux';
import { selectError, selectIsLoading } from '../app/reducers/uiReducer';
import { selectToken } from '../app/reducers/staffReducer';
import { useNavigate } from 'react-router-dom';
import Asynstorage from '@react-native-async-storage/async-storage'
import { style } from '../util';

const Login = () =>{
    const dispatch = useDispatch()
    const staff = new Staff(dispatch)
    const navigate = useNavigate()
    const token = useSelector(selectToken)
    const Error = useSelector(selectError)
    const isLoading = useSelector(selectIsLoading)
    const [body, setBody] = useState({
        userName: '',
        password: ''
    })

    const handleChange = e => {
        const { name, value } = e.target
        setBody({
            ...body,
            [name]: value
        })
    }

    const hanldeLogIn = () => {
        staff.staffLogIn(body)
    }    

    const handleAuth = async (auth) => {
        if(!auth) return null
        axios.defaults.headers.common['Authorization'] = `Bearer ${auth}`
        await Asynstorage.setItem('token', auth)
        navigate('/dashboard')
    }

    useEffect(() =>{
        handleAuth(token)
    }, [token])

    return (
        <main className={style.logInContainer}>
            <section
                className={style.loginBox}
            >
                <div className={style.iconContainer}>
                    <img 
                        src={logo}
                        className={style.logo}
                    />
                    <h3>Welcome Back</h3>

                </div>
                <div>
                    <span className={Error? style.error : ""}>
                        {Error ? Error : 'Username'}
                    </span>
                    <div className={style.inputContainer}>

                        <input 
                            type="text"
                            name='userName'
                            value={body.userName}
                            onChange={e =>handleChange(e)}
                            placeholder='User name or Email address'
                        />
                    </div>
                </div>
                <div>
                    <span className={Error? style.error : ""}>
                        {Error ? Error : 'Password'}
                    </span>
                    <div className={style.inputContainer}>
                        <input 
                            type="password"
                            name='password'
                            value={body.password}
                            placeholder='Password'
                            onChange={e =>handleChange(e)}
                        />
                    </div>
                </div>
                <div className={style.buttonContainer}>
                    <button type='button'
                        onClick={hanldeLogIn}
                        disabled={isLoading}
                    >
                       {isLoading ? 'Loading...' : 'Log In'}
                    </button>
                </div>
            </section>
        </main>
    );
}

export default Login;
import React from 'react';
import { style, avatar as avataricon, USER_DOMAIN } from '../util';

const Title = ({title, value}) => {
    return (
        <div
            className={style.cardInfo}
        >
            <h3> &#x20A6; {value}</h3>
            <p>{title}</p>
        </div>
    )
}

const UserCard = ({ balance, tranType, amount, bookBalance, oldBalance}) =>{
    return (
        <div className={style.userCardContainer}>
            <div className={style.avatar}
                style={{
                    borderRadius: 10
                }}
            >
                <img 
                     style={{
                        borderRadius: 10
                    }}
                    className={style.avatarImage}
                    src={avataricon} alt="Profile" />

            </div>
            <div className={style.cardInfoContainer}>
                {
                    amount &&
                    <Title 
                        value={tranType === 'dr' ? oldBalance - amount : oldBalance + amount}
                        title='Balance'
                    />
                }
                
                {oldBalance &&
                <Title 
                    value={oldBalance}
                    title='Old Balance'
                />}
                {
                balance &&
                <Title 
                    value={balance}
                    title='Balance'
                />}
                <Title 
                    value={bookBalance}
                    title='Book Balance'
                />
            </div>
        </div>
    );
}

export default UserCard;
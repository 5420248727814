import axios from 'axios'
import { setToken } from '../reducers/staffReducer'
import {  setTransactions } from "../reducers/transactionReducer"
import { setError, setIsLoading } from "../reducers/uiReducer"
import AsyncStorage from '@react-native-async-storage/async-storage'

export class Transaction {
    constructor(dispatch) {
        this.dispatch = dispatch
    }

    getAll =  async (page, limit) =>{
        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            this.dispatch(setTransactions(null))
            const { data, status } = await axios.get(`/transaction/${page}/${limit}`)
            if(status === 200) return this.dispatch(setTransactions(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setTransactions(null))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }

    getByStatus =  async (page, limit, tranStatus) =>{
        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))   
            this.dispatch(setTransactions(null))
            const { data, status } = await axios.get(`/transaction/status/${page}/${limit}/${tranStatus}`)
            if(status === 200) return this.dispatch(setTransactions(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setTransactions(null))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }

    getByDate =  async (page, limit, from, to, product) =>{

        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            this.dispatch(setTransactions(null))
            const { data, status } = await axios.get(`/transaction/date/${page}/${limit}/${from}/${to}/${product}`)
    
            if(status === 200) return this.dispatch(setTransactions(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setTransactions(null))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }

    getByDateProductAndStatus =  async (page, limit, from, to, product, tranStatus) =>{

        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            this.dispatch(setTransactions(null))
            const { data, status } = await axios.get(`/transaction/date/transtatus/${page}/${limit}/${from}/${to}/${product}/${tranStatus}`)
    
            if(status === 200) return this.dispatch(setTransactions(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setTransactions(null))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }

    queryByTxIDTxType =  async (query) =>{
        try {
            this.dispatch(setIsLoading(true))
            this.dispatch(setError(null))
            this.dispatch(setTransactions(null))
            const { data, status } = await axios.get(`/transaction/query/${query}`)
    
            if(status === 200) return this.dispatch(setTransactions(data)),  this.dispatch(setIsLoading(false))
        } catch (error) {
            const {status} = error?.response
            this.dispatch(setError(error.response?.data?.message))
            this.dispatch(setIsLoading(false))
            this.dispatch(setTransactions(null))
            if(status === 401) return (
                this.dispatch(setToken(null)),
                AsyncStorage.removeItem('token')
            )
        }
    }


   
}

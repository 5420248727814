import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { selectUsers, setSelectedUser } from '../app/reducers/userReducer';
import { ToUpperCase } from '../util';
import { setPopover } from '../app/reducers/uiReducer';

const RenderTr = ({ index, onClick, firstName, lastName, member_status, contactDetails, createdAt}) => {
    return (
        <Fragment>
            <tr 
                onClick={() =>onClick()}
                className={index % 2 ? 'trDecorationColor': ''}
            >
                <td> {index+1} </td>
                <td>{`${ToUpperCase(firstName)} ${ToUpperCase(lastName)}`}</td>
                <td> 
                    {contactDetails?.phoneNumber}                    
                </td>
                <td> {contactDetails?.email}</td>
                <td>{new Date(createdAt).toLocaleDateString()}</td>
                
                
                <td>
                   {ToUpperCase(member_status)}
                </td>
                <td>
                    <Icon icon="ic:more" className='icon' />
                </td>
            </tr>
        </Fragment>
    )
}


const UserTable = ({search}) =>{
    const users = useSelector(selectUsers)
    const dispatch = useDispatch()
    return (
        <Fragment>
            {
                users?.length >  0 &&
            
                <table className='tableHead'>
                    <thead>
                    
                        <Fragment>
                        <th>S/N</th>
                        <th>Full Name</th>
                        <th>Phone Number</th>
                        <th>Email Address</th>
                        <th>Date Joined</th>
                        <th>Status</th>
                        <th>View</th>
                        </Fragment>
                        
                    </thead>
                    
                    <tbody className='tableBody'>
                        {
                            users?.length > 0 &&
                            users?.users?.filter(items =>{
                                if(!search){
                                    return items
                                }else if(items?.firstName.includes(search)){
                                    return items
                                }else if(items?.contactDetails?.phoneNumber?.includes(search)){
                                    return items
                                }else if(items?.contactDetails?.email?.includes(search)){
                                    return items
                                }else if(items?.virtualAccounts[0]?.accountNumber?.includes(search))
                                    return items
                                

                            })
                            
                            .map((item, index) => {
                                return <RenderTr 
                                    index={index}
                                    {...item}
                                    key={index+1*878987}
                                    onClick={() => {
                                        dispatch(setSelectedUser(item))
                                        dispatch(setPopover(true))
                                    }}
                                />
                            })
                        }
                    </tbody>
                
                </table>
            }
        </Fragment>
    );
}

export default UserTable;
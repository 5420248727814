import React, { useState } from 'react';
import { style } from '../util';
import { CSVLink } from 'react-csv'
import { useDispatch } from 'react-redux';
import { setPopover } from '../app/reducers/uiReducer';

const ExportReport = ({name, headers, dataSet, setName, setIsDownload}) =>{
    const dispatch = useDispatch()
    const [downloaded, setDownloaded] = useState(false)
    const handlePrint = () => {
        setDownloaded(true)

        setTimeout(() => {
            setName('')
            setIsDownload(false)
            dispatch(setPopover(false))
            setDownloaded(false)
            // done(true)

        }, 5000);

    }
    return (
        <CSVLink 
            title={name}
            filename={name}
            data={dataSet}
            headers={headers}
            children={
                <button className={style.downloadBtn}>
                    { downloaded ? 'Downloading...' :'Download'}
                </button>
            }
            onClick={(e, done) => handlePrint()}
        />
    )
}

export default ExportReport;
import { React, Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { User } from "../app/customHooks/userHooks"
import { selectStaff, selectToken } from "../app/reducers/staffReducer"
import { selectIsLoading, selectPopover, setPopover } from "../app/reducers/uiReducer"
import Apploader from "../components/Apploader"
import Aside from "../components/Aside"
import Header from "../components/Header"
import UserTable from "../components/UserTable"
import { COLORS, SIZES, style, ToUpperCase } from "../util"
import { FaSearch } from 'react-icons/fa'
import { selectSelectedUser, selectUsers } from "../app/reducers/userReducer"
import Popover from "../components/Popover"
import UserCard from "../components/UserCard"
import ListItem from "../components/ListItem"
import { Level } from "../app/customHooks/levelHook"
import { selectLevels } from "../app/reducers/levelReducer"

const Admin = () => {
    const dispatch = useDispatch()
    const user = new User(dispatch)
    const level = new Level(dispatch)
    const levels = useSelector(selectLevels)
    const session = useSelector(selectStaff)
    const users = useSelector(selectUsers)
    const isLoading = useSelector(selectIsLoading)
    const isPopover = useSelector(selectPopover)
    const selectedUser = useSelector(selectSelectedUser)
    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState('')
    const [selected, setSelected] = useState()
    const token = useSelector(selectToken)
    const [query, setQuery] = useState({
        page: 1,
        limit: 50
    })
    const [toggleSide, setToggleSide] = useState(1)
    const handleSearch = () => {
        const {page, limit} = query
        user.getAll(page, limit)
    }
    const fetchLevels = () => {
        level.getLevels()
    }
    useEffect(() => {
        if(!token) return
        handleSearch()
        fetchLevels()
    }, [token])

    useEffect(() => {
        const item = levels?.find(item => item._id === selected)
        setLimit(item?.limit)
    }, [selected])
    return (

        <Fragment>
            {
                isLoading &&
                <Apploader />
            }
            {
                isPopover &&
                <Popover 
                    small
                    title='User Details'
                    children={
                        <div style={{width: '100%'}}>
                            <br />
                            <UserCard 
                                balance={selectedUser?.walletId?.walledBalance ? parseInt(selectedUser?.walletId?.walledBalance ): 0}
                                bookBalance={selectedUser?.walletId?.bookBalance ? parseInt(selectedUser?.walletId?.bookBalance ): 0}
                                
                            />
                            <div className={style.limitContainer}>
                                <h3
                                className={style.title}
                                >
                                    {
                                        `${ToUpperCase(selectedUser?.firstName)} ${ToUpperCase(selectedUser?.lastName)}`
                                    }
                                </h3>
                                <div className={style.inputContainer}>
                                    {
                                        levels?.length > 0 &&
                                    
                                    <select id="level"
                                        value={selected}
                                        onChange={e =>setSelected(e.target.value)}
                                    >
                                        <option value={0}>select</option>
                                       
                                        {
                                            levels?.length > 0 && levels?.map(item => {
                                                return (
                                                    <Fragment>
                                                        <option value={item?._id}> {item.name} </option>
                                                    </Fragment>
                                                )
                                            })
                                        }
                                    </select>
                                    }
                                    <input 
                                        value={limit}
                                        type='number'
                                        style={{margin: 10}}
                                        placeholder='limit 50000'
                                        onChange={e => setLimit(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <button 
                                        disabled={isLoading}
                                        className={style.limitBtn}
                                        onClick={() => user.setUserLimit({limit, id: selected})}
                                    >
                                        {isLoading ? 'Processing...' : 'Set Limit'}
                                    </button>
                                </div>
                                
                            </div>
                            <section>


                                <div
                                    style={{
                                        paddingVertical: 15
                                    }}
                                >
                                    <hr color={COLORS.white2} style={{marginTop: 40}} />
                                    <div
                                        style={
                                            // styles.flexRow,
                                            {   marginTop: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }
                                        }
                                    >
                                        <p
                                            style={{
                                                marginTop: -20,
                                                width: 200,
                                                borderWidth: 1,
                                                fontSize: 12,
                                                fontWeight: '500',
                                                padding: '10px 0px 10px 0px',
                                                
                                                textAlign: 'center',
                                                color: COLORS.darkGreen,
                                                borderRadius: SIZES.radius + 5,
                                                borderColor: COLORS.gray1,
                                                backgroundColor: COLORS.white2
                                            }}
                                        >
                                            {new Date(selectedUser?.createdAt).toUTCString()?.split(',')[1]?.split('GMT')[0]}
                                        </p>
                                    </div>
                                </div>
                                    
                                {
                                    selectedUser?.virtualAccounts?.length> 0&&
                                    <Fragment>
                                        <ListItem 
                                            title='Account Nmae'
                                            value={selectedUser?.virtualAccounts[0]?.accountName}
                                        />
                                        <ListItem 
                                            title='Account Number'
                                            value={selectedUser?.virtualAccounts[0]?.accountNumber}
                                        />
                                         <ListItem 
                                            title='Account Status'
                                            value={selectedUser?.virtualAccounts[0]?.accountBlackList ? "Black listed": "Open"}
                                        />
                                    </Fragment>
                                }
                                <br />
                                <ListItem 
                                    title={selectedUser?.contactDetails?.phoneNumber}
                                    value={selectedUser?.PKYC ? "Verified": "Not Verify"}
                                />
                                <ListItem 
                                    title={selectedUser?.contactDetails?.email}
                                    value={selectedUser?.EKYC ? "Verified": "Not Verify"}
                                />
                            </section>
                        </div>
                    }
                    title='Transaction Details'
                    setClose={() =>(
                        dispatch(setPopover(false))
                       
                    )}
                />
            }
            <div className="appWrapper">

                <div className={toggleSide? "asideAreaSlide" : "asideArea"}>

                    <Aside 
                        toggleSide={toggleSide}
                        setToggleSide={setToggleSide}
                    />

                </div>

                <div className={toggleSide? "sectionAreaSlide" : "sectionArea"} >
                    
                    <Header 
                        title={"Users"} 
                        userName={`${ToUpperCase(session?.firstName)} ${ToUpperCase(session?.lastName)}`}
                    />
                    

                    <div className="adminWrap"
                        style={{
                            position: 'sticky',
                            overflowY: 'auto',
                            height: '90vh'

                        }}
                    >
                        <div style={{
                            top: 0,
                            zIndex: 2,
                            width: '100%',
                            height: 40,
                            background:'#ededed',
                            position: 'sticky'
                        }}>
                            <div
                                style={{
                                    gap: 4,
                                    width: 900,
                                    height: 40,
                                    margin: 'auto',
                                    outline: 'none',
                                    border: 'none',
                                    paddingRight: 10,
                                    display: 'flex',
                                    background:'white ',
                                    alignItems: 'center',
                                    borderRadius: '5px'
                                }}
                                className={style.shadow}
                            >
                                <input 
                                    value={search}
                                    placeholder='Search... with name, phone number, email, account number'
                                    style={{
                                        padding: 10,
                                        width: '100%',
                                        outline: 'none',
                                        border: 'none',
                                        background: 'none'
                                    }}
                                    onChange={e =>setSearch(e.target.value)}
                                />
                                <FaSearch />
                            </div>
                                
                           
                        </div>
                        
                        <UserTable search={search} />

                    </div>
                    <div
                        style={{
                            display: 'flex',
                            width: '90%',
                            margin: 'auto',
                            paddingBottom: 5,
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <button className='spoolBtn'
                            style={{
                                width: 200,
                                padding: '10px',
                                border: 'none',
                                outline: 'none',
                                borderRadius: 5
                            }}
                        >
                            Previous
                        </button>
                        <div
                            style={{
                                gap: 4,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <form onSubmit={e => {
                                e.preventDefault()
                                handleSearch()
                            }}>
                                <div
                                    style={{
                                        padding: 4,
                                        display: 'flex',
                                        marginRight: 10,
                                        borderRadius: 5,
                                        alignItems: 'center',
                                        border: '1px solid gray',
                                       
                                    }}
                                >
                                    <input 
                                        type='number'
                                        value={query.limit}
                                        placeholder='Limit'
                                        style={{
                                            padding: '10px',
                                            border: 'none',
                                            outline: 'none',
                                            background: 'none'
                                        }}
                                        onChange={e =>setQuery({
                                            ...query,
                                            limit: e.target.value
                                        })}
                                    />
                                    <FaSearch 
                                        size={22} 
                                        color='gray' 
                                        onClick={handleSearch}
                                    />
                                </div>
                            </form>
                            <p>{users?.pages}</p>
                        </div>
                        <button className='spoolBtn'
                            style={{
                                width: 200,
                                padding: '10px',
                                border: 'none',
                                outline: 'none',
                                borderRadius: 5
                            }}
                            onClick={() =>{
                                setQuery({
                                    ...query,
                                    page: query.page +1
                                })
                                handleSearch()
                            }}
                        >
                            Next
                        </button>
                    </div>

                </div>

            </div>


       </Fragment>
        
    )

}

export default Admin
import React from 'react';
import { style } from '../util';

const ListItem = ({ naira, title, value}) =>{
    return (
        <div className={style.listContainer}>
            <p> {title} </p>
           {    naira ?
                <h4> &#x20A6; {value} </h4> :
                <h4>{value} </h4>
           }
        </div>
    );
}

export default ListItem;
import React from 'react';
import { style } from '../util';
import { Icon } from '@iconify/react';

const Popover = ({children, title, report, small, large, medium, setClose  }) =>{
    return (
        <main className={style.popoverContainer}>
           
            <section 
                className={style.popoverBody}
                style={{
                    width: small ? '35%' : large ? '80%' : medium ? '50%' : '40%'
                }}
            >
               { 
                    !report &&
                    <div className={style.popoverClose}>
                        <div>
                            <Icon 
                                icon="ic:close"
                                className={style.icon}
                                style={{
                                    fontSize: 34
                                }}
                                onClick={() => setClose()}
                            />
                        </div>
                    </div>
                }
                <div className={style.popoverHeader}
                    style={{
                        borderBottom: report ? 'none' : ''
                    }}
                >
                    <h3> {title} </h3>
                </div>
                <div className={style.popoverWrapper}>
                    {children}
                </div>
            </section>
        </main>
    );
}

export default Popover;
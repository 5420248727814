import React, { Fragment } from 'react';

const SpoolDate = ({query, setQuery, setSwap}) => {
    return (
        <Fragment>
            <span> From </span>
            <input 
                type="date" 
                value={query?.from}
                onChange={e =>(setQuery({
                    ...query,
                    from: e.target.value
                }), setSwap())}
            />
            <span> To </span>
            <input 
                type="date" 
                value={query?.to}
                onChange={e =>(setQuery({
                    ...query,
                    to: e.target.value
                }), setSwap())}
            />
        </Fragment>
    );
}

export default SpoolDate;
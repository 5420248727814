import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data: null,
    token: null,
}

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers: {
        setStaff: (state, action) => {
            state.data = action.payload
        },
        setToken: (state, action) => {
            state.token = action.payload
        }
    }
})

export const { setToken, setStaff } = staffSlice.actions

export const selectStaff = state => state.staff.data 
export const selectToken = state => state.staff.token

export default staffSlice.reducer
import { React, Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Transaction } from "../app/customHooks/transactionHooks"
import { selectStaff, selectToken } from "../app/reducers/staffReducer"
import { setSelectedTx } from "../app/reducers/transactionReducer"
import { selectIsLoading, selectPopover, setPopover } from "../app/reducers/uiReducer"
import Apploader from "../components/Apploader"
import Aside from "../components/Aside"
import ExportForm from "../components/ExportForm"
import Header from "../components/Header"
import Popover from "../components/Popover"
import QueryTable from "../components/QueryTable"
import TransactionTable from "../components/TransactionTable"
import TransactionType from "../components/TransactionType"
import TxDetails from "../components/TxDetails"
import { ToUpperCase } from "../util"

const Overview = () => {
    const dispatch = useDispatch()
    const [swap, setSwap] = useState(1)
    const [name, setName] = useState('')
    const token = useSelector(selectToken)
    const session = useSelector(selectStaff)
    const [status, setStatus] = useState()
    const isPopover = useSelector(selectPopover)
    const isLoading = useSelector(selectIsLoading)

    const [toggleSide, setToggleSide] = useState(1)
    const [isDownload, setIsDownload] = useState(false)

    const transactiion = new Transaction(dispatch)
    const [query, setQuery] = useState({
       
        page: 1,
        limit: 50,
        to: null,
        from: null,
        product: 0,
        tranStatus: ''
    })

    const handleSearch = (e) => {
        if(!token) return
        const { to, from, page, limit, product  } = query
        // alert(swap)
        if(swap === 1) {
            return transactiion.getAll(page,limit)
        }
        if(swap === 2) {
            return transactiion.getByStatus(page,limit, 1)
        }
        if(swap === 3) {
            return transactiion.getByStatus(page,limit, 2)
        }
        if(swap === 4) {
            return transactiion.getByStatus(page,limit, 0)
        }
        if(swap === 5 && from !==null & to !==null) {
            return transactiion.getByDate(page, limit, from, to, product)
        }

        if(swap === 6 && from !==null & to !==null) {
            return transactiion.getByDateProductAndStatus(page, limit, from, to, product, status)
        }

    }



    useEffect(() => {
        handleSearch()
    }, [token, swap])
    return (

        <Fragment>
           
            <div className="appWrapper">
                {
                    isLoading &&
                    <Apploader />
                }
                {
                    isPopover && !isDownload &&
                    <Popover 
                        small
                        children={
                            <TxDetails />
                        }
                        title='Transaction Details'
                        setClose={() =>(
                            dispatch(setPopover(false)),
                            dispatch(setSelectedTx(null))
                        )}
                    />
                }
                {
                    isPopover && isDownload &&
                    <Popover 
                        small
                        report
                        children={
                        <ExportForm 
                            swap={swap}
                            name={name}
                            query={query}
                            setName={setName}
                            setQuery={setQuery}
                            setIsDownload={() =>{
                                setIsDownload(false)
                                dispatch(setPopover(false))
                                if(swap ===6 ) return setSwap(0)
                            }}
                        />
                        }
                        title='Export report'
                        setClose={() =>(
                            setIsDownload(false),
                            dispatch(setPopover(false))
                        )}
                    />
                }
                <div className={toggleSide? "asideAreaSlide" : "asideArea"}>

                    <Aside 
                        toggleSide={toggleSide}
                        setToggleSide={setToggleSide}
                    />

                </div>

                <div className={toggleSide? "sectionAreaSlide" : "sectionArea"} >

                    <Header 
                        title={"Overview"} 
                        userName={`${ToUpperCase(session?.firstName)} ${ToUpperCase(session?.lastName)}`}
                    
                    />

                    <TransactionType 
                       
                        swap={swap} 
                        query={query}
                        status={status}
                        setSwap={setSwap} 
                        setQuery={setQuery}
                        setStatus={setStatus}
                        handleSearch={handleSearch}
                        setIsDownload={() =>setIsDownload(true)}
                    />

                    <div className="subsections">

                        <TransactionTable 
                            swap={swap}
                            query={query}
                            setQuery={setQuery}
                            handleSearch={handleSearch}
                            setIsDownload={setIsDownload}
                        />
                        <QueryTable />

                    </div>

                </div>

            </div>

        </Fragment>

    )

}

export default Overview
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTransactions, setTransactions } from '../app/reducers/transactionReducer';
import { COLORS, PRODUCTID_IDS, style, ToUpperCase } from '../util';
import ExportReport from './ExportReport';
import SpoolDate from './SpoolDate';
import { Icon } from '@iconify/react';
import { CSVLink } from 'react-csv';
import { selectIsLoading } from '../app/reducers/uiReducer';
import { Transaction } from '../app/customHooks/transactionHooks';

const headers = [
    {label: "SN", key: 'sn'},
    {label: 'Description', key: 'description'},
    {label: 'Amount', key: 'amount'},
    {label: 'Date', key: 'date'},
    {label: 'Transaction ID', key: 'tranId'},
    {label: 'User', key: 'user'},
    {label: 'Beneficiary', key: 'beneficiary'},
    {label: 'Status', key: 'status'}
]

const ExportForm = ({name, swap, query, setQuery, setName, setIsDownload}) =>{
    const dispatch = useDispatch()
    const tx = new Transaction(dispatch)
    const [data, setData] = useState([])
    const [show, setShow] = useState(false)
    const isLoading = useSelector(selectIsLoading)
    const transactions = useSelector(selectTransactions)

    const handleFilter = data =>{
       if(!data) return
        const dataSet = data?.map((item, index) => {
            const sn = index+1
            const { amount, tranId, tranType, memberId, productId, accNumber, tranStatus, createdAt } = item
            const description = PRODUCTID_IDS[`${productId}`]
            const status = tranStatus === 1 ? 'Successful' :  tranStatus === 2 ? 'Pending' : 'Failed'
            const date = new Date(createdAt).toUTCString()?.split(',')[1]?.split('GMT')[0]
            const user = `${ToUpperCase(memberId?.firstName)} ${ToUpperCase(memberId?.lastName)}`
            
            return {
                sn,
                description,
                amount,date,
                tranId, user, beneficiary: accNumber, status, tranType
            }
        })

        setData(dataSet)
        // setShow(true)
    }
    const handleSearch = async ()  => {
        setShow(false)
        // dispatch(setTransactions([]))
        const page = 1
        const limit = 0
        const product = query?.product
        const { from, to } = query
        await tx.getByDate(page, limit, from, to, product )
        setShow(true)
    }


    const dataFromListOfUsersState = () =>{
        return data
    }
    useEffect(() => {
        handleFilter(transactions?.transactions)
    }, [transactions])

    return (
        <div className={style.exportForm}>
            {
                swap === 6 &&
                <div className='sortWrap'>
                    <SpoolDate 
                        query={query}
                        setSwap={() =>{}}
                        setQuery={setQuery}
                    />
                    <button className='spoolBtn'
                        onClick={handleSearch}
                        disabled={!query?.from || !query?.to}
                    >
                        {isLoading ? 'Searching...' :'Search'}
                        <Icon 
                            className={style.icon}
                            icon="ic:baseline-search" 
                        />
                    </button>
                </div>
            }
            {
                swap !== 6 &&
                <Fragment>
                    <p>To export transaction report, kindly type a below, minimun of 3 letters word</p>
                    <div className={style.inputContainer}>
                    
                    <input 
                        type="text" 
                        value={name}
                        name="exportname" 
                        onChange={e =>setName(e.target.value)}
                        placeholder='Export name ...'
                    />
                </div>
                </Fragment>
            }
            <div className={style.exportFormBtn}>
                <button className={style.downloadBtn}
                    style={{
                        color: COLORS.red,
                        background: 'none',
                        border: `0.5px solid ${COLORS.red}`
                    }}
                    onClick={() =>setIsDownload()}
                >
                    Close
                </button>
                {
                    swap === 6 ?
                    <Fragment>

                    {
                        show &&
                        <CSVLink 
                            title={name}
                            filename={name}
                            data={dataFromListOfUsersState()}
                            headers={headers}
                            
                            children={
                                <button className={style.downloadBtn}>
                                    { isLoading ? 'Downloading...' :'Download'}
                                </button>
                            }
                            onClick={() =>setIsDownload()}
                        />
                    }
                    </Fragment>

                    :
                    <Fragment>
                        {
                            data?.length > 0 && name?.length > 3 && 
                            <ExportReport 
                                name={name}
                                setName={setName}
                                disabled={!name?.trim().length > 3}
                                dataSet={data}
                                headers={headers}
                                setIsDownload={setIsDownload}
                            />
                        }
                    </Fragment>
                }
            </div>
        </div>
    );
}

export default ExportForm;
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    items: []
}

const levelSlice = createSlice({
    name: 'level',
    initialState,
    reducers: {
        setLevels: (state, action) => {
            state.items = action.payload
        }
    }
})

export const { setLevels } = levelSlice.actions

export const selectLevels = state => state.level.items

export default levelSlice.reducer